import { PasswordConditionsSatisfied, PasswordPolicy } from "../types/PasswordPolicy";
import { validateRegex } from "./regex";
import { removeAllWhitespaces } from "./stringUtils";

export const validateEmail = (email: string): boolean => {
  const re = /^([a-zA-Z0-9_+][a-zA-Z0-9_+.-]*)+@([\w-]+\.)+[\w]/;
  
  return re.test(email);
};

export const validatePassword = (password: string): boolean => {
  const re = /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*])(?=.{8,})/;

  return re.test(password);
};

export const validateOnPasswordPolicy = (password: string, 
    { passwordLength, isNumRequired, isSpecialCharRequired, isBothCaseCharRequired }: PasswordPolicy, 
    isWhitespaceRestricted: boolean): PasswordConditionsSatisfied => {
      
  let response: PasswordConditionsSatisfied = { passwordLength: false, isNumRequired: false, isSpecialCharRequired: false, isBothCaseCharRequired: false, isWhitespaceRestricted: false };

  let regexPattern = "";
  if (passwordLength) {
    regexPattern = `(?=.{${passwordLength},})`;
    if (validateRegex(regexPattern, removeAllWhitespaces(password))) {
      response.passwordLength = true;
    }
  }

  // Add conditions for numbers, special characters, and uppercase letters
  if (isNumRequired) {
    regexPattern = "(?=.*[0-9])";
    if (validateRegex(regexPattern, password)) {
      response.isNumRequired = true;
    }
  }else{
    response.isNumRequired = true;
  }

  if (isSpecialCharRequired) {
    // Note: You might need to escape certain special characters
    regexPattern = "(?=.*[!@#$%^&*])";
    if (validateRegex(regexPattern, password)) {
      response.isSpecialCharRequired = true;
    }
  }else{
    response.isSpecialCharRequired = true;
  }

  if (isBothCaseCharRequired) {
    regexPattern = "(?=.*[A-Z])(?=.*[a-z])";
    if (validateRegex(regexPattern, password)) {
      response.isBothCaseCharRequired = true;
    }
  }else{
    response.isBothCaseCharRequired = true;
  }

  if(isWhitespaceRestricted){
    if (!(/\s/.test(password))) {
      response.isWhitespaceRestricted = true;
    }
  }else{
    response.isWhitespaceRestricted = true;
  }

  return response;
}